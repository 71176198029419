<template>
  <div>
    <x-el-table
      ref="table"
      :card="false"
      :options="options"
      @search="searchHandle"
      @rowUnbind="rowUnbind"
      @rowSelected="rowSelected">
      <template slot="actions">
        <x-button name="绑定" icon="UserPlusIcon" @click="showBindModal"></x-button>
        <x-button name="解绑" icon="UserMinusIcon" variant="light-danger" class="ml-1" @click="unbind"></x-button>
      </template>
    </x-el-table>
    <b-modal
      id="bind-user"
      size="xl"
      :visible="showModal"
      title-class="x-text-bold"
      body-class="x-scrollbar pl-4 pr-4"
      ok-title="保存"
      cancel-title="取消"
      cancel-variant="flat-secondary"
      title="选择用户"
      centered
      @hidden="hideModal"
      @ok="enterModal">
      <x-el-table
        ref="bind_table"
        :card="false"
        :options="userOptions"
        @search="searchUserHandle"
        @rowSelected="rowUserSelected"
        @rowSelectedOk="rowUserSelectedOk">
      </x-el-table>
    </b-modal>
  </div>
</template>

<script>
import { BModal } from 'bootstrap-vue'
import XElTable from '@/@core/components/cx/table/XElTable.vue'
import XButton from '@/@core/components/cx/button/XButton.vue'
import {
  getUserListByRoleId, unbind, queryUnbindUsers, bind,
} from '@/api/system/setup/role'

export default {
  components: {
    XElTable,
    BModal,
    XButton,
  },
  props: {
    role: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      showModal: false,
      selectRows: [],
      selectUnbindUserRows: [],
      options: {
        rowSelectable: true,
        hideStyleSwitch: true,
        addBtn: false,
        actions: [
          { action: 'unbind', tip: '解绑', icon: 'UserXIcon' },
        ],
        columns: [{
          label: '姓名',
          prop: 'name',
        }, {
          label: '用户名',
          prop: 'username',
        }, {
          label: '状态',
          prop: 'status',
          rowSelect: true,
          type: 'select',
          dictData: [{ label: '正常', value: 1 }, { label: '已禁用', value: 0 }],
          selectVariant: prop => {
            if (prop === 1) {
              return 'success'
            }
            return 'danger'
          },
        }],
      },
      userOptions: {
        contentHeight: '55vh',
        rowSelectable: true,
        rowSelectedOkBtnShow: true,
        hideStyleSwitch: true,
        addBtn: false,
        actions: [],
        columns: [{
          label: '姓名',
          prop: 'name',
        }, {
          label: '用户名',
          prop: 'username',
        }, {
          label: '状态',
          prop: 'status',
          rowSelect: true,
          type: 'select',
          dictData: [{ label: '正常', value: 1 }, { label: '已禁用', value: 0 }],
          selectVariant: prop => {
            if (prop === 1) {
              return 'success'
            }
            return 'danger'
          },
        }],
      },
    }
  },
  watch: {
    role() {
      this.$refs.table.__searchHandle()
    },
  },
  methods: {
    searchHandle(page, params, done) {
      if (!this.role) {
        done([], 0)
        return
      }
      params.roleId = this.role.id
      getUserListByRoleId(page.pageIndex - 1, page.pageSize, params).then(resp => {
        done(resp.data.result.data, resp.data.result.total)
      })
    },
    rowUnbind(data, done) {
      unbind(this.role.id, [data.id]).then(() => {
        done()
      })
    },
    rowSelected(rows) {
      this.selectRows = rows
    },
    showBindModal() {
      this.showModal = true
    },
    hideModal() {
      this.showModal = false
      this.selectUnbindUserRows = []
    },
    enterModal(bvModalEvt) {
      if (!this.selectUnbindUserRows || this.selectUnbindUserRows.length === 0) {
        this.$xtoast.warning('请至少选择一条数据！')
        bvModalEvt.preventDefault()
        return
      }
      let params = []
      this.selectUnbindUserRows.forEach(user => {
        let roleUser = {}
        roleUser.dimensionId = this.role.id
        roleUser.dimensionName = this.role.name
        roleUser.dimensionTypeId = 'role'
        roleUser.userId = user.id
        roleUser.userName = user.name
        params.push(roleUser)
      })
      bind(params).then(() => {
        this.$xtoast.success('绑定成功！')
        // this.$refs.bind_table.__searchHandle()
        this.$refs.table.__searchHandle()
        this.showModal = false
      })
    },
    unbind() {
      if (!this.selectRows || this.selectRows.length === 0) {
        this.$xtoast.warning('请至少选择一条数据！')
        return
      }
      unbind(this.role.id, this.selectRows.map(item => item.id)).then(() => {
        this.$xtoast.success('解绑成功！')
        this.selectRows = []
        this.$refs.table.__searchHandle()
      })
    },
    searchUserHandle(page, params, done) {
      if (!this.role) {
        done([], 0)
        return
      }
      queryUnbindUsers(this.role.id, page.pageIndex - 1, page.pageSize, params).then(resp => {
        done(resp.data.result.data, resp.data.result.total)
      })
    },
    rowUserSelected(rows) {
      this.selectUnbindUserRows = rows
    },
    rowUserSelectedOk(rows, done) {
      if (!this.selectUnbindUserRows || this.selectUnbindUserRows.length === 0) {
        this.$xtoast.warning('请至少选择一条数据！')
        return
      }
      let params = []
      this.selectUnbindUserRows.forEach(user => {
        let roleUser = {}
        roleUser.dimensionId = this.role.id
        roleUser.dimensionName = this.role.name
        roleUser.dimensionTypeId = 'role'
        roleUser.userId = user.id
        roleUser.userName = user.name
        params.push(roleUser)
      })
      bind(params).then(() => {
        done()
        this.$refs.table.__searchHandle()
        this.showModal = false
      })
    },
  },
}
</script>

<style lang="scss">
</style>
