<template>
  <div>
    <x-table
      title="角色列表"
      :card="true"
      :options="options"
      @search="searchHandle"
      @rowAdd="addHandle"
      @rowEdit="rowEdit"
      @rowDelete="rowDelete"
      @rowBind="rowBind"
      @rowAuthz="rowAuthz">
    </x-table>
    <b-sidebar
      id="sidebar-right"
      ref="slider"
      idebar-class="sidebar-xl"
      bg-variant="white"
      :visible="visible"
      right
      backdrop
      shadow
      width="75%"
      title="用户绑定"
      @hidden="hidden"
    >
      <role-users
        ref="form"
        v-if="visible"
        :role="role"
      />
    </b-sidebar>
    <b-sidebar
      ref="slider"
      idebar-class="sidebar-xl"
      bg-variant="white"
      title="权限分配"
      right
      backdrop
      shadow
      width="45%"
      :visible="visibleFlag"
      @hidden="authzHide"
    >
      <authz-form
        v-if="visibleFlag"
        :targetId="targetId"
        :targetType="targetType"
        @success="authzHide"
      />
    </b-sidebar>
  </div>
</template>
<script>
import XTable from '@core/components/cx/table/XTable.vue'
import {
  BSidebar,
} from 'bootstrap-vue'
import {
  query, add, edit, delById,
} from '@/api/system/setup/role'
import AuthzForm from '@/views/system/setup/utils/AuthzForm.vue'
import RoleUsers from './RoleUsers.vue'

export default {
  components: {
    XTable,
    RoleUsers,
    BSidebar,
    AuthzForm,
  },
  data() {
    return {
      visible: false,
      visibleFlag: false,
      // 赋权参数
      targetId: undefined,
      // 赋权参数
      targetType: 'role',
      role: {},
      options: {
        formType: 'sidebar',
        formWidth: '60%',
        // formWidth: 'lg',
        searchFold: true,
        lableVertical: true,
        // 导出按钮
        exportBtn: false,
        // 打印按钮
        printBtn: false,
        actions: [
          // { action: 'view', name: '查看', icon: 'EyeIcon' },
          // actions: [{ action: 'view', name: '查看', icon: 'EyeIcon', url: '/device-center/device/view/{id}' },
          { action: 'edit', name: '编辑', icon: 'EditIcon' },
          { action: 'delete', name: '删除', icon: 'Trash2Icon' },
          { action: 'bind', name: '绑定用户', icon: 'UsersIcon' },
          { action: 'authz', name: '权限分配', icon: 'PocketIcon' },
        ],
        columns: [{
          label: '角色标识',
          labelShow: true,
          prop: 'id',
          searchShow: true,
          editDisable: true,
          rules: {
            rule: 'required',
            message: '请输入角色标识',
          },
        }, {
          label: '角色名称',
          labelShow: true,
          prop: 'name',
          searchShow: true,
          rules: {
            rule: 'required',
            message: '请输入角色名称',
          },
        }, {
          label: '描述',
          labelShow: true,
          type: 'textarea',
          prop: 'describe',
          searchShow: false,
        },
        ],
      },
    }
  },
  created() {
  },
  methods: {
    searchHandle(page, params, done) {
      query(page.pageIndex - 1, page.pageSize, params).then(resp => {
        done(resp.data.result.data, resp.data.result.total)
      })
    },
    rowEdit(data, done) {
      data.typeId = 'role'
      edit(data).then(() => {
        done()
      })
    },
    rowDelete(data, done) {
      delById(data.id).then(() => {
        done()
      })
    },
    addHandle(formData, done) {
      formData.typeId = 'role'
      add(formData).then(() => {
        done()
      })
    },
    rowBind(data) {
      this.role = data
      this.visible = true
    },
    hidden() {
      this.visible = false
      this.role = {}
    },
    rowAuthz(data) {
      this.visibleFlag = true
      this.targetId = data.id
    },
    authzHide() {
      this.visibleFlag = false
      this.targetId = undefined
    },
  },
}
</script>

<style lang="scss" scoped>
  .per-page-selector {
    width: 90px;
  }
</style>

<style lang="scss">
</style>
