import request from '@/auth/jwt/useJwt'

// 获取用户数据
export function query(pageIndex, pageSize, params) {
  let requestUrl = `dimension/_query?pageIndex=${pageIndex}&pageSize=${pageSize}&terms[0].column=typeId&terms[0].value=role&sorts%5B0%5D.name=id&sorts%5B0%5D.order=desc`
  let index = 1
  if (params) {
    if (params.name) {
      requestUrl += `&terms[${index}].column=name$LIKE&terms[${index}].value=%${params.name}%`
      index += 1
    }
    if (params.id) {
      requestUrl += `&terms[${index}].column=id$LIKE&terms[${index}].value=%${params.id}%`
    }
  }
  return request.axiosIns({
    url: encodeURI(requestUrl),
    method: 'get',
  })
}
/*  新增用户  */
export function add(data) {
  return request.axiosIns({
    url: '/dimension',
    method: 'POST',
    data,
  })
}
/* 编辑角色 */
export function edit(data) {
  return request.axiosIns({
    url: '/dimension',
    method: 'PATCH',
    data,
  })
}
/* 删除证书 */
export function delById(id) {
  return request.axiosIns({
    url: `dimension/${id}`,
    method: 'DELETE',
  })
}

/* 查询已经绑定的用户 */
export function getUserListByRoleId(pageIndex, pageSize, params) {
  let requestUrl = `user/_query?pageIndex=${pageIndex}&pageSize=${pageSize}&terms[0].column=id$in-dimension$role&terms[0].value=${params.roleId}`
  let index = 1
  if (params.name) {
    requestUrl += `&terms[${index}].column=name$LIKE&terms[${index}].value=%${params.name}%`
    index++
  }
  if (params.username) {
    requestUrl += `&terms[${index}].column=username$LIKE&terms[${index}].value=%${params.username}%`
  }
  return request.axiosIns({
    url: encodeURI(requestUrl),
    method: 'get',
  })
}

/* 解除绑定（多个） */
export function unbind(roleId, params) {
  return request.axiosIns({
    url: `dimension-user/user/role/${roleId}/_unbind`,
    method: 'post',
    data: params,
  })
}
/* 绑定用户（多个） */
export function bind(params) {
  return request.axiosIns({
    url: 'dimension-user',
    method: 'PATCH',
    data: params,
  })
}
/* 查询可以绑定的用户（已经绑定的不查询） */
export function queryUnbindUsers(roleId, pageIndex, pageSize, params) {
  let requestUrl = `user/_query?pageIndex=${pageIndex}&pageSize=${pageSize}&terms[0].column=id$in-dimension$role$not&terms[0].value=${roleId}`
  if (params) {
    let index = 1
    if (params.name) {
      requestUrl += `&terms[${index}].column=name$LIKE&terms[${index}].value=%${params.name}%`
      index++
    }
    if (params.username) {
      requestUrl += `&terms[${index}].column=username$LIKE&terms[${index}].value=%${params.username}%`
    }
  }
  return request.axiosIns({
    url: encodeURI(requestUrl),
    method: 'get',
  })
}
